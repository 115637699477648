<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">Billings</h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div class="row">
              <div class="col-sm-2 col-md-2 col-lg-2">
                <b-form-input v-model="filter.domain_name" placeholder="Website" @input="search"></b-form-input>
              </div>
              <div class="col-sm-2 col-md-2 col-lg-2">
                <b-form-input
                  v-model="filter.organisation"
                  placeholder="Organisation Name"
                  @input="search"
                ></b-form-input>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3">
                <b-form-select v-model="filter.other" @change="search" :options="payment_methods"></b-form-select>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3">
                <b-form-select v-model="filter.status" @change="search" :options="payment_status"></b-form-select>
              </div>
              <div class="col-sm-2 col-md-2 col-lg-2">
                <b-button variant="outline-primary" @click="resetForm">Reset</b-button>
              </div>
            </div>
            <b-table
              hover
              :items="data"
              id="my-table"
              :busy.sync="isBusy"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(invoice_total)="data">{{ data.item.invoice_no }}</template>
              <template v-slot:cell(orderDate)="data">{{ data.item.cart.order_paid_time }}</template>
              <template v-slot:cell(Payment_method)="data">{{ data.item.cart.payment_method }}</template>
              <template v-slot:cell(status)="data">{{ data.item.cart.payment_status }}</template>
              <template
                v-slot:cell(invoiceTotal)="data"
              >{{ data.item.currency }} {{ data.item.invoice_total }}</template>
              <template v-slot:cell(action)="data">
                <div class="kt-widget__toolbar">
                  <router-link
                    :to="{ name: 'invoice', params: { cartId:  data.item.cart.id, invoiceId:data.item.id } }"
                    class="kt-nav__link"
                  >
                    <i class="kt-nav__link-icon flaticon-eye"></i>
                    Invoice
                  </router-link>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import InvoiceService from "@/services/invoice/InvoiceService";
const inovice = new InvoiceService();

export default {
  name: "billing",
  data() {
    return {
      isBusy: false,
      sortBy: "invoiceNumber",
      sortDesc: false,
      payment_status: [
        { value: null, text: "Payment Status" },
        { value: "pending", text: "Pending" },
        { value: "completed", text: "Completed" },
        { value: "cancelled", text: "Cancelled" }
      ],
      payment_methods: [
        { value: null, text: "Select Payment Method" },
        { value: "paypal", text: "Paypal" },
        { value: "khalti", text: "Khalti" },
        { value: "stripe", text: "Stripe" },
        { value: "esewa", text: "esewa" }
      ],
      filter: {
        domain_name: null,
        organisation: null,
        status: null,
        other: null
      },
      fields: [
        { key: "invoice_total", label: "#", sortable: true },
        { key: "orderDate", sortable: true },
        { key: "Payment_method", label: "Payment Method", sortable: true },
        { key: "status", sortable: true },
        { key: "invoiceTotal", label: "Invoice Total", sortable: false },
        { key: "action", sortable: false }
      ],
      data: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15]
    };
  },
  methods: {
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },

    getBillings() {
      inovice.getAllByUser().then(response => {
        this.data = response.data.data;
        // this.itemsPerPage = response.data.meta.per_page;
        // this.page = response.data.meta.current_page;
        // this.pageCount = response.data.meta.last_page;
      });
    },

    resetForm() {
      this.filter = {
        domain_name: null,
        organisation: null,
        status: null,
        other: null
      };
      this.search();
    },
    search() {
      inovice
        .search(this.filter)
        .then(response => {
          this.data = [];
          this.data = response.data;
        })
        .catch(error => {});
    }
  },
  computed: {
    rows() {
      return this.data.length;
    }
  },
  mounted() {
    this.getBillings();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Billings", route: "dashboard" }
    ]);
  }
};
</script>
